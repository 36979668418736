import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'
import VueCookie from 'vue-cookie'
import { loadLanguageAsync } from '@/lang/config'

const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [{
    path: '*',
    redirect: '/login'
  }, {
    path: '/tenantsavailable',
    name: 'tenantsavailable',
    component: () => import('@/views/Auth/TenantProfile.vue')
  }, {
    path: '/whatsnew',
    name: 'whatsnew',
    component: () => import('@/views/NewFeatures/Whatsnew.vue')
  }, {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Auth/Login.vue'),
    beforeEnter (from, to, next) {
      if (!VueCookie.get(process.env.VUE_APP_TOKEN)) {
        VueCookie.delete(process.env.VUE_APP_NAME + '_secondary_tokenPWA')
        next()
      } else {
        next('/dashboard')
      }
    }
  }, {
    path: '/widgets_dashboard',
    component: () => import('@/views/WidgetsDashboard/Index.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/dashboard',
    component: () => import('@/views/Dashboard/Index.vue'),
    meta: { requiresAuth: true },
    children: [{
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Dashboard/List.vue')
    }]
  }, {
    path: '/custom_modules',
    component: () => import('@/views/Dashboard/MoreModules.vue'),
    meta: { requiresAuth: true, availableToAll: true }
  }, {
    path: '/tickets',
    component: () => import('@/views/Tickets/Index.vue'),
    meta: { requiresAuth: true, restrictedFeature: true, value: 11 },
    children: [{
      path: '/',
      name: 'ticket_list',
      component: () => import('@/views/Tickets/List.vue')
    }, {
      path: 'create',
      name: 'create',
      component: () => import('@/views/Tickets/CreateTicket.vue')
    }, {
      path: 'actions/:ticket_id?',
      name: 'action',
      component: () => import('@/views/Tickets/Action.vue')
    }]
  }, {
    path: '/module/:name/:id',
    component: () => import('@/views/CustomModules/Index.vue'),
    meta: { requiresAuth: true },
    children: [{
      path: '/',
      name: 'custom_list',
      component: () => import('@/views/CustomModules/List.vue')
    }, {
      path: 'actions/:record_id?',
      name: 'custom_action',
      component: () => import('@/views/CustomModules/Actions.vue')
    }, {
      path: 'view/:record_id?',
      name: 'custom_view',
      component: () => import('@/views/CustomModules/View.vue')
    }, {
      path: 'dashboard',
      name: 'sales-dashboard',
      component: () => import('@/views/CustomModules/Dashboard/Index.vue')
    }]
  }, {
    path: '/timer',
    name: 'timer',
    meta: { requiresAuth: true, restrictedFeature: true, value: 10 },
    component: () => import('@/views/Timer/Index.vue')
  }, {
    path: '/approval',
    name: 'approval',
    meta: { requiresAuth: true },
    component: () => import('@/views/Timer/Approval/Index.vue')
  }, {
    path: '/stemp',
    name: 'stemp',
    meta: { requiresAuth: true, restrictedFeature: true, value: 10 },
    component: require('@/views/Timer/Stemp.vue').default,
    beforeEnter: (to, from, next) => {
      const value = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}_Timer_enablestamptime`))
      if (value) next()
      else next('/timer')
    }
  }, {
    path: '/calendar',
    name: 'calendar',
    meta: { requiresAuth: true, availableToAll: true },
    component: () => import('@/views/Calendar/Index.vue')
  }, {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: () => import('@/views/Auth/ForgotPassword.vue')
  }, {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import('@/views/Auth/ResetPassword.vue')
  }]
})

router.beforeEach((to, from, next) => {
  const getLang = to.query.lang ? to.query.lang : VueCookie.get(process.env.VUE_APP_LOCALE) ? VueCookie.get(process.env.VUE_APP_LOCALE) : 'en'
  loadLanguageAsync({ lang: getLang }).then(() => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!VueCookie.get(process.env.VUE_APP_TOKEN)) {
        store.dispatch('logout')
        next({
          path: '/login'
        })
      } else {
        if (to.matched.some(record => record.meta.restrictedFeature)) { // Checking routes whether the restricted feature is available
          const value = to.matched.find(record => record.meta.restrictedFeature).meta.value
          if (store.getters.userDetails.allowedfeatures && store.getters.userDetails.allowedfeatures.length > 0 && store.getters.userDetails.allowedfeatures.includes(value)) {
            next()
          } else next('/dashboard')
        } else next()
      }
    } else {
      next()
    }
  })
})

export default router
