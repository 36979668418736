/* eslint-disable no-console */

import { register } from 'register-service-worker'
import store from './store/index'

if (process.env.NODE_ENV === 'production') {
  console.log('inside service worker')
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; please refresh.')
      store.commit('siteNewContent', registration)
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
  if ('serviceWorker' in navigator) {
    let refreshing
    navigator.serviceWorker.addEventListener('controllerchange', e => {
      console.log('controll changed' + refreshing)
      if (refreshing) return
      window.location.reload()
      refreshing = true
    })
  }
}
