import moment from 'moment'
import momentTz from 'moment-timezone'
import VueCookie from 'vue-cookie'
import { decrypt } from '../utils/crypto'

const user = VueCookie.get(process.env.VUE_APP_USER) ? decrypt(JSON.parse(VueCookie.get(process.env.VUE_APP_USER))) : null
let timezone = (user) ? localStorage.getItem((`${process.env.VUE_APP_NAME}_${user.tenantname}_tz`)) : null
timezone = (timezone && timezone !== 'undefined') ? JSON.parse(timezone) : Intl.DateTimeFormat().resolvedOptions().timeZone
moment.tz.setDefault(timezone)

export default {
  install (Vue, options) {
    Vue.prototype.$formatter = {
      updateTimeZone () {
        const user = VueCookie.get(process.env.VUE_APP_USER) ? decrypt(JSON.parse(VueCookie.get(process.env.VUE_APP_USER))) : null
        let timezone = (user) ? localStorage.getItem((`${process.env.VUE_APP_NAME}_${user.tenantname}_tz`)) : null
        timezone = (timezone && timezone !== 'undefined') ? JSON.parse(timezone) : Intl.DateTimeFormat().resolvedOptions().timeZone
        moment.tz.setDefault(timezone)
      },
      replaceDotWithComma (value) {
        if (value === null || value === undefined || value === '') return ''
        return parseFloat(value).toFixed(2).toString().replace('.', ',')
      },
      replaceDotWithCommaWOFixed (value) {
        if (value === null || value === undefined || value === '') return ''
        return value.toString().replace('.', ',')
      },
      replaceCommaWithDot (value) {
        if (!value) return null
        return value.toString().replace(',', '.')
      },
      replaceCommaWithDotCal (value) {
        if (!value) return 0
        return parseFloat(value.toString().replace(',', '.'))
      },
      cloneVariable (data) {
        if (!data) return null
        return JSON.parse(JSON.stringify(data))
      },
      filterDate (date) {
        if (!date) return null
        return moment(date, 'DD.MM.YYYY').isValid() ? moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY') : null
      },
      formatDate (date, fromFormat, toFormat) {
        if (!date) return null
        return moment(date, (fromFormat || 'DD.MM.YYYYTHH.mm.ss')).isValid() ? moment(date, (fromFormat || 'DD.MM.YYYYTHH.mm.ss')).format((toFormat || 'DD.MM.YYYY HH:mm')) : null
      },
      formatMomentDate (date, toFormat) {
        if (!date) return null
        return moment(date).format(toFormat)
      },
      // formatDateTimeForPicker (datePicker, timePicker) {
      //   if (datePicker && moment(datePicker).isValid()) datePicker = moment(datePicker).format('DD.MM.YYYY')
      //   else datePicker = this.getCurrentDate()
      //   const dateTime = moment(timePicker, 'HH:mm').format('HH:mm')
      //   return `${datePicker} ${dateTime}`
      // },
      formatDateTimeForPicker (datePicker, timePicker) {
        const user = VueCookie.get(process.env.VUE_APP_USER) ? decrypt(JSON.parse(VueCookie.get(process.env.VUE_APP_USER))) : null
        const dateformat = (user) ? user.dateformat : null
        if (datePicker && moment(datePicker).isValid()) datePicker = moment(datePicker).format(dateformat)
        else datePicker = this.getCurrentDate(dateformat)
        const dateTime = moment(timePicker, 'HH:mm').format('HH:mm')
        return `${datePicker} ${dateTime}`
      },
      // fromUtcToLocal (date, fromFormat) {
      //   if (!date) return null
      //   date = this.formatDate(date, fromFormat, 'DD.MM.YYYY HH:mm')
      //   return date ? moment.utc(date, 'DD.MM.YYYYTHH:mm:ss').toDate() : null
      // },
      // fromUtcToLocal (date, fromFormat, toFormat) {
      //   if (!date) return null
      //   // date = this.formatDate(date, fromFormat, 'DD.MM.YYYY HH:mm')
      //   // return date ? toFormat ? moment(moment.utc(date, 'DD.MM.YYYYTHH:mm:ss').toDate()).format(toFormat) : moment.utc(date, 'DD.MM.YYYYTHH:mm:ss').toDate() : null
      //   return moment(moment.utc(date, (fromFormat || 'DD.MM.YYYYTHH.mm.ss')).toDate()).format((toFormat || 'DD.MM.YYYYTHH.mm.ss'))
      // },
      // Return moment object
      localeMoment () {
        return moment()
      },
      // formatUTC (date, format) {
      //   if (date) return moment.utc(date, 'DD.MM.YYYYTHH:mm:ss').local().format(format)
      //   else return null
      // },
      isNullOrEmpty (val) {
        return val === undefined || val === null || val === ''
      },
      isNull (val) {
        return val === null
      },
      isEmptyObject (obj) {
        return Object.entries(obj).length === 0 && obj.constructor === Object
      },
      isArrayHasData (array) {
        if (array && array.length > 0) return true
        else return false
      },
      isArray (array) {
        return Array.isArray(array)
      },
      getRandomNumberInBW (min, max) {
        return parseInt(Math.random() * (max - min) + min)
      },
      debounce (func, delay) {
        let debounceTimer
        return function () {
          const context = this
          const args = arguments
          clearTimeout(debounceTimer)
          debounceTimer = setTimeout(() => func.apply(context, args), delay)
        }
      },
      // _getSumBy([4, 2, 8, 6]) // 20
      getSum (arr) {
        return [...arr].reduce((acc, val) => acc + val, 0)
      },
      // _getSumBy([{ n: 4 }, { n: 2 }, { n: 8 }, { n: 6 }], x => x.n) // 20
      getSumBy (arr, fn) {
        const self = this
        if (this.isArrayHasData(arr)) {
          return arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce(function (acc, val) {
            return self.replaceCommaWithDotCal(acc) + self.replaceCommaWithDotCal(val)
          }, null)
        } else return null
      },
      getCurrentDate (format = 'DD.MM.YYYY') {
        return moment().format(format)
      },
      getCurrentDateTimeUTC () {
        // return moment.utc().format('DD.MM.YYYYTHH:mm:ss')
        const user = VueCookie.get(process.env.VUE_APP_USER) ? decrypt(JSON.parse(VueCookie.get(process.env.VUE_APP_USER))) : null
        let timezone = (user) ? localStorage.getItem((`${process.env.VUE_APP_NAME}_${user.tenantname}_tz`)) : null
        timezone = (timezone && timezone !== 'undefined') ? JSON.parse(timezone) : Intl.DateTimeFormat().resolvedOptions().timeZone
        return momentTz().tz(timezone).format('DD.MM.YYYYTHH:mm:ss')
      },
      getCurrentWeekNumber () {
        return moment().isoWeek()
      },
      getSameorBefore (currentDate, date) {
        return moment(date).isSameOrBefore(currentDate)
      },
      groupBy (array, key, value) {
        return array.reduce(function (total, item) {
          const prop = item[key] ? item[key] : value
          total[prop] = total[prop] || []
          total[prop].push(item)
          return total
        }, {})
      },
      // _findValueInArray([{ n: 4, b: 10 }, { n: 2, b: 11 }], x => x.b === 10) // { n: 4, b: 10 }
      findValueInArray (array, condition) {
        const getObj = array.find(condition)
        if (getObj) return getObj
        else return null
      },
      // _getSingleValueFromObject([{ n: 4, b: 10 }, { n: 2, b: 11 }], x => x.b) // [10, 11]
      mapDataInArray (array, condition) {
        const getObj = array.map(condition)
        if (getObj) return getObj
        else return null
      },
      enumerateDaysBetweenDates (startDate, endDate) {
        const now = startDate
        const dates = []
        while (now.isSameOrBefore(endDate)) {
          dates.push(now.format('DD.MM.YYYY'))
          now.add(1, 'days')
        }
        return dates
      },
      formatModel (model, data) {
        model = JSON.parse(JSON.stringify(model))
        const keys = Object.keys(data)
        keys.forEach(key => {
          switch (key) {
            case 'number': {
              const formatObj = data.number
              formatObj.list.forEach(item => {
                model[item] = this[formatObj.format](model[item])
              })
              break
            }
            case 'date': {
              const formatObj = data.date
              formatObj.list.forEach(item => {
                const dateObj = this.formatDate(model[item], formatObj.from, formatObj.to)
                model[item] = dateObj
              })
              break
            }
            case 'status': {
              const formatObj = data.status
              formatObj.list.forEach(item => {
                const statusArray = this[formatObj.value]
                statusArray.map(function (x) {
                  if (x.value === model[item]) model[item] = { text: x.text, value: model[item] }
                })
              })
              break
            }
            default:
              break
          }
        })
        return model
      },
      // Array of $_formatModel FUNCTION
      formatListModel (listOfModel, data) {
        if (this.isArrayHasData(listOfModel)) {
          const result = []
          listOfModel.forEach(model => {
            result.push(this.formatModel(model, data))
          })
          return result
        } else return []
      },
      sortByType (item1, item2) {
        if (typeof (item1) === 'string' && typeof (item2) === 'string') {
          if (/^\d+$/.test(item2) && /^\d+$/.test(item1)) {
            return item1.localeCompare(item2, undefined, {
              numeric: true
            })
          }
          return item1.localeCompare(item2)
        } else {
          return item1 < item2 ? -1 : 1
        }
      },
      activeTabStyle (color, text, isDark) {
        if (!isDark) {
          return {
            boxShadow: `${color} 0px 0px 5px -1px`,
            borderBottom: `2px solid ${color}`,
            color: text,
            backgroundImage: `linear-gradient(to right, ${color}, ${this.hexToRgbaHalfOpacity(color)})`
          }
        } else {
          return {
            color: 'black',
            backgroundColor: 'white'
          }
        }
      },
      hexToRgbaHalfOpacity (hex) {
        let c
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
          c = hex.substring(1).split('')
          if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
          }
          c = '0x' + c.join('')
          return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.5)'
        }
      },
      updateMomentLocale (lang) {
        if (lang) {
          if (lang === 'no') moment.locale('nb')
          else moment.locale(lang)
        } else {
          moment.locale('en')
        }
      },
      tabStyle (color) {
        return {
          boxShadow: `${color} 0px 0px 5px -1px`,
          borderBottom: `2px solid ${color}`
        }
      },
      parseDate (date) {
        const user = VueCookie.get(process.env.VUE_APP_USER) ? decrypt(JSON.parse(VueCookie.get(process.env.VUE_APP_USER))) : null
        const dateformat = (user) ? user.dateformat : null
        return moment(date, 'YYYY-MM-DD').isValid() ? moment(date, 'YYYY-MM-DD').format(dateformat || 'DD.MM.YYYY') : null
      },
      // Object format and parse numbers
      formatAndParseModel (model, listOfValues, methodName) {
        const modelData = this.cloneVariable(model)
        listOfValues.forEach(element => {
          modelData[element] = parseFloat(this[methodName](modelData[element]))
        })
        return modelData
      },
      // Array format and parse number
      formatAndParseList (list, listOfValues, methodName) {
        if (list.length > 0) {
          const result = []
          list.forEach(element => {
            result.push(this.formatAndParseModel(element, listOfValues, methodName))
          })
          return result
        } else return []
      },
      customSorting (items, index, isDesc, dateColumnArray, type) {
        items.sort((a, b) => {
          if (dateColumnArray.includes(index)) {
            const date1 = a[index]
            const date2 = b[index]
            if (!isDesc) {
              return this.sortWithDates(date1, date2, type)
            } else {
              return this.sortWithDates(date2, date1, type)
            }
          } else {
            if (!a[index]) a[index] = ''
            if (!b[index]) b[index] = ''
            if (!isDesc) {
              return this.sortByType(a[index], b[index])
            } else {
              return this.sortByType(b[index], a[index])
            }
          }
        })
        return items
      },
      sortWithDates (date1, date2, type) {
        if (date1 === '' || date1 === null) return -1
        if (date2 === '' || date2 === null) return 1
        var dateA = moment(date1, type)
        var dateB = moment(date2, type)
        return dateA - dateB
      },
      // Set Foreground color based on the background color
      foreGroundColor (colorValue) {
        const rgbCode = colorValue ? this.hexToRgbA(colorValue) : ''
        const color = rgbCode ? rgbCode.toString().split('(')[1] : ''
        const resultOnColor = color.split(',', 3)
        const R = resultOnColor[0]
        const G = resultOnColor[1]
        const B = resultOnColor[2] ? resultOnColor[2].toString().replace(')', '') : 0
        const C = [R / 255, G / 255, B / 255]
        for (var i = 0; i < C.length; ++i) {
          if (C[i] <= 0.03928) {
            C[i] = C[i] / 12.92
          } else {
            C[i] = Math.pow((C[i] + 0.055) / 1.055, 2.4)
          }
        }
        const L = 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2]
        if (L > 0.179) {
          return 'black'
        } else {
          return 'white'
        }
      },
      hexToRgbA (hex) {
        hex = hex.slice(0, -2)
        var c
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
          c = hex.substring(1).split('')
          if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
          }
          c = '0x' + c.join('')
          return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)'
        }
        return null
      },
      stringToProperCase (value) {
        return value.toLowerCase().split('_').map(function (word) {
          return (word.charAt(0).toUpperCase() + word.slice(1))
        }).join('_')
      },
      getHumanzieTime (time, locale) {
        moment.locale(locale)
        return (moment(time, 'DD.MM.YYYY HH:mm:ss').isValid()) ? moment(time, 'DD.MM.YYYY HH:mm:ss').fromNow() : null
      },
      mimeTypeOfDocument (type) {
        let image = ''
        switch (type) {
          case 'image/png':
          case 'image/jpeg':
          case 'image/gif':
          case 'image/svg+xml':
            image = { icon: 'mdi-file-image', color: 'grey' }
            break
          case 'application/pdf':
            image = { icon: 'mdi-file-pdf', color: 'error' }
            break
          case 'text/html':
            image = { icon: 'mdi-language-html5', color: '#f16428' }
            break
          case 'video/mp4':
            image = { icon: 'mdi-file-video', color: 'grey' }
            break
          case 'audio/mpeg':
            image = { icon: 'mdi-music', color: 'grey' }
            break
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
            image = { icon: 'mdi-file-word', color: 'info' }
            break
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
            image = { icon: 'mdi-file-excel', color: 'green' }
            break
          case 'application/vnd.ms-powerpoint':
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          case 'application/vnd.openxmlformats-officedocument.presentationml.template':
          case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
            image = { icon: 'mdi-file-powerpoint', color: 'error' }
            break
          case 'application/x-rar-compressed':
          case 'application/octet-stream':
          case 'application/zip':
          case 'application/x-zip-compressed':
          case 'multipart/x-zip':
            image = { icon: 'mdi-zip-box', color: 'grey' }
            break
          case 'folder':
            image = { icon: 'mdi-folder', color: '#ffda6b' }
            break
          default:
            image = { icon: 'mdi-file-cloud', color: 'grey' }
            break
        }
        return image
      },
      // Permission Handling
      permissionSetting (listOfModules, moduleId, userDetails) {
        const modules = this.cloneVariable(listOfModules)
        const result = modules.find(x => x._id === moduleId)
        let accesscontrol = {
          add: false,
          view: false,
          edit: false,
          delete: false
        }
        if (userDetails.isadmin || userDetails.iscontentadmin) {
          accesscontrol = {
            add: true,
            view: true,
            edit: true,
            delete: true
          }
        } else {
          accesscontrol = result.accesscontrol
          // if (result && result.permissions && result.permissions.length > 0) {
          //   const userPermission = result.permissions.find(x => x.user_id === userDetails._id)
          //   if (userPermission) {
          //     if (userPermission.access_level === 'manage') accesscontrol = true
          //     else accesscontrol = false
          //   } else {
          //     let found = []
          //     userDetails.groups.forEach(item => {
          //       found = [...found, ...result.permissions.filter(x => x.group_id === item)]
          //     })
          //     if (found && found.length > 0) {
          //       const hasManageAccess = found.find(x => x.access_level === 'manage')
          //       if (hasManageAccess) accesscontrol = true
          //       else accesscontrol = false
          //     } else accesscontrol = false
          //   }
          // }
        }
        return accesscontrol
      },
      getAppImageURL () {
        return process.env.VUE_APP_IMAGE_URL
      }
    }
  }
}
