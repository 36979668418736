import { MAILBOX, TICKETSTATUS, TICKETPRIORITY, GROUPS, TICKETCATEGORY, ESTABLISHSIGNALRCONNECTION } from './actionsTypes'
import { SET_LISTVALUES, SINGANLRENABLED, SET_SIGNALR_CONNECTION_ID, SET_NEW_NOTIFICATIONS_FOUND_VALUE } from './mutationsTypes'
import { api } from '../plugins/axios_settings'
import cookie from 'vue-cookie'
import store from './index'
import eventBus from '../main'

const state = {
  listOfMailboxes: [],
  listOfPriorities: [],
  listOfStatus: [],
  listOfTypes: [],
  listOfGroups: [],
  listOfTicketCategory: [],
  signalRConnection: {},
  signalRConnectionId: null,
  newNotificationFound: false,
  // Filters
  F_ALL_TICETS: {},
  F_OPEN_TICETS: {},
  F_UNASSIGNED_TICETS: {},
  F_MY_ACTIVE: {},
  F_CLOSED: {},
  F_ACCOUNT_TICKETS: {}
}

const getters = {
  getListOfMailboxes (state) {
    return state.listOfMailboxes
  },
  getListOfTicketPriorities (state) {
    return state.listOfPriorities
  },
  getListOfTicketStatus (state) {
    return state.listOfStatus
  },
  getListOfTicketType (state) {
    return state.listOfTypes
  },
  getsignalRConnection (state) {
    return state.signalRConnection
  },
  getListOfGroups (state) {
    return state.listOfGroups
  },
  getListOfTicketCategory (state) {
    return state.listOfTicketCategory
  },
  getsignalRConnectionId (state) {
    return state.signalRConnectionId
  },
  getNewNotificationFoundValue (state) {
    return state.newNotificationFound
  }
}

const actions = {
  [MAILBOX] ({ commit }) {
    return new Promise((resolve, reject) => {
      const model = [{
        $project: { Name: 1 }
      }]
      api.post('mailconfigurations/query', model)
        .then((response) => {
          const model = { type: 'listOfMailboxes', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [TICKETSTATUS] ({ commit }) {
    return new Promise((resolve, reject) => {
      const model = [{
        $match: { IsActive: true }
      }]
      api.post('ticketstatus/query', model)
        .then((response) => {
          const model = { type: 'listOfStatus', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [TICKETPRIORITY] ({ commit }) {
    return new Promise((resolve, reject) => {
      const model = [{
        $match: { IsActive: true }
      }]
      api.post('ticketpriorities/query', model)
        .then((response) => {
          const model = { type: 'listOfPriorities', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [TICKETCATEGORY] ({ commit }) {
    return new Promise((resolve, reject) => {
      const model = [{
        $match: { IsActive: true }
      }]
      api.post('ticketcategories/query', model)
        .then((response) => {
          const model = { type: 'listOfTicketCategory', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [GROUPS] ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('usergroups')
        .then((response) => {
          response.data.forEach(element => {
            element.alternateid = JSON.parse(JSON.stringify(`group_${element._id}`))
            element.isGroup = true
          })
          const model = { type: 'listOfGroups', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [ESTABLISHSIGNALRCONNECTION] ({ commit }) {
    const signalR = require('@aspnet/signalr')
    const connection = new signalR.HubConnectionBuilder().withUrl(`${process.env.VUE_APP_API_URL}/CrmHub`, {
      transport: signalR.HttpTransportType.WebSockets,
      accessTokenFactory: () => cookie.get(process.env.VUE_APP_TOKEN)
    }).configureLogging(signalR.LogLevel.Information).build()

    connection
      .start()
      .then(() => {
        const url = connection.connection.transport.webSocket.url
        const searchParams = new URLSearchParams(new URL(url).search)
        var { id: connectionId } = Object.fromEntries(searchParams.entries()) || {}
        if (connectionId) {
          console.info('SignalR connected:', connectionId)
          commit('setSignalRConnectionId', connectionId)
        }

        connection.onclose(() => {
          // console.error('SignalR connection closed', navigator.onLine, new Date())
          commit('setSignalRConnectionId', null)
          store.dispatch('establishSignalR')
        })
        commit('signalREnable', connection)
      })
      .catch(() => {
        console.warn('SignalR not connected.')
      })

    if (!connection) return
    connection.on('LoadNotification', () => { // app notification emitter
      eventBus.$emit('new-notification')
      store.commit('setNewNotificationsFoundValue', true)
    })
  }
}

const mutations = {
  [SET_LISTVALUES] (state, obj) {
    state[obj.type] = obj.data
  },
  [SINGANLRENABLED] (state, data) {
    state.signalRConnection = data
  },
  [SET_SIGNALR_CONNECTION_ID] (stateObj, id) {
    stateObj.signalRConnectionId = id
  },
  [SET_NEW_NOTIFICATIONS_FOUND_VALUE] (stateObj, value) {
    stateObj.newNotificationFound = value
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
