<template>
  <v-card height="100%" flat tile>
    <v-app-bar app fixed :style="`background: ${ $vuetify.theme.dark ? '' : systemDetails.themecolor }; color: ${ $vuetify.theme.dark ? '' : (systemDetails.textcolor || '#fff') }`">
      <v-btn icon small @click="$router.go(-1)" :style="`color: ${systemDetails.textcolor}`" class="mx-1">
        <v-icon size="20"> mdi-arrow-left </v-icon>
      </v-btn>
      <span class="mx-auto" style="font-size: 1.3rem;">
        <span class="ml-n7">{{ currentDate }} </span>
      </span>
    </v-app-bar>
    <v-card-text class="pa-0">
      <v-container fluid class="pa-0">
        <v-card-title class="justify-center">
          <span :key="time" class="display-1 font-weight-bold"> {{ time }} </span>
        </v-card-title>
        <!-- <v-row>
          <v-col cols="12" align-self="center" class="text-center pt-11 pb-7">
            <span :key="time" class="display-1 font-weight-bold"> {{ time }} </span>
          </v-col>
        </v-row> -->
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <!-- Just added comments -->
          <v-col cols="12" xs="12" sm="6" md="5" lg="4">
            <v-card flat class="pa-0 overflow-y-auto">
              <form-data :references.sync="formReferences" :model="timerObj" ref="hourReference"></form-data>
              <v-card-actions class="pa-0 pt-6 pb-0">
                <v-spacer></v-spacer>
              </v-card-actions>
              <v-card flat class="pa-0" style="position: sticky; bottom: 0; z-index: 1; display: flex; justify-content: center; margin-bottom: 5%;">
                <v-badge bordered color="error" icon="mdi-stop" overlap v-if="timerData.isTimeRunning">
                  <v-btn tile color="error" dark @click="stopTimer" :loading="loading" class="mr-2"> {{ $t('stop') }} </v-btn>
                </v-badge>
                <v-badge bordered color="success" icon="mdi-play" overlap v-else>
                  <v-btn elevation="3" color="success" tile dark @click="start" class="mr-2"> {{ $t('start') }} </v-btn>
                </v-badge>
              </v-card>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-card-text>
    <!-- <v-card-actions class="pt-6 pb-0">
      <v-spacer></v-spacer>
      <v-badge bordered color="error" icon="mdi-stop" overlap v-if="timerData.isTimeRunning">
        <v-btn tile color="error" dark @click="stopTimer" :loading="loading" class="mr-2"> {{ $t('stop') }} </v-btn>
      </v-badge>
      <v-badge bordered color="success" icon="mdi-play" overlap v-else>
        <v-btn elevation="3" color="success" tile dark @click="start" class="mr-2"> {{ $t('start') }} </v-btn>
      </v-badge>
      <v-spacer></v-spacer>
    </v-card-actions> -->
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import TimerMixin from '../../mixins/timer'
export default {
  mixins: [TimerMixin],
  data () {
    return {
      // currentDay: this.$moment().format('dddd'),
      currentDate: this.$formatter.getCurrentDate(),
      time: '00:00:00',
      webWorkerInstance: null,
      timerObj: {
        _id: null,
        projectid: null,
        customerSearch: '',
        projectSearch: ''
      },
      listOfTasks: [],
      isInitital: true,
      getCurrentProjects: [],
      listOfCustomers: [],
      customerSearchLoading: false,
      projectSearchLoading: false,
      enableCustomerSearch: true,
      enableProjectSearch: true,
      loading: false,
      isTypeAbsence: false,
      maxFormHeight: '55vh',
      searchItem: '',
      skip: 0
    }
  },
  components: {
    'form-data': () => import('@/components/FormTemplate')
  },
  computed: {
    ...mapGetters(['userDetails', 'formType', 'getWorktypes', 'timerData', 'getTimerFormSettings', 'getTimeCodes', 'systemDetails', 'getTimerSettings']),
    formReferences () {
      return {
        properties: [{
          model: 'customerid',
          // live_search_model: 'customerSearch',
          live_search_model: this.getTimerSettings && this.getTimerSettings.filteraccountsbyassignee ? '' : 'customerSearch',
          // type: this.formType.AUTO_COMPLETE_LIVE,
          type: this.getTimerSettings && this.getTimerSettings.filteraccountsbyassignee ? this.formType.AUTO_COMPLETE : this.formType.AUTO_COMPLETE_LIVE,
          rules: this.$_requiredValidation,
          items: this.listOfCustomers,
          select_text: 'name',
          select_value: '_id',
          disabled: false,
          label: this.$t('customers'),
          class: 'xs12',
          keydown: this.preventSpecialCharacters,
          change: () => {
            this.timerObj.projectid = null
            this.customerChangeHandler()
          },
          loading: this.customerSearchLoading,
          // change: () => {
          //   this.timerObj.projectid = null // eslint-disable-line
          //   this.getCurrentProjects = [] // eslint-disable-line
          //   if (this.enableCustomerSearch) {
          //     if (this.getTimerSettings.filterprojectsbyassignee) {
          //       this.loadProjectData()
          //     }
          //   }
          // },
          is_visible: (this.getTimerFormSettings ? this.getTimerFormSettings.customer : false) && !this.isTypeAbsence
        }, {
          model: 'projectid',
          live_search_model: this.getTimerSettings.filterprojectsbyassignee && this.getTimerSettings.filteraccountsbyassignee ? '' : 'projectSearch',
          // live_search_model: 'projectSearch',
          type: this.getTimerSettings && this.getTimerSettings.filterprojectsbyassignee ? this.formType.AUTO_COMPLETE : this.formType.AUTO_COMPLETE_LIVE,
          // type: this.formType.AUTO_COMPLETE_LIVE,
          rules: this.$_requiredValidation,
          items: this.getCurrentProjects,
          select_text: 'projectName',
          select_value: '_id',
          keydown: this.preventSpecialCharacters,
          change: () => {
            if (this.timerObj._id) this.saveRecord()
            // if (this.timerObj.projectid && this.timerObj.projectid !== 0) this.getTasksByProject(this.timerObj.projectid)
            // else this.listOfTasks = [] // eslint-disable-line
            this.projectChangeHandler()
          },
          label: this.$t('project'),
          class: 'xs12',
          is_visible: (this.getTimerFormSettings ? this.getTimerFormSettings.project : false) && !this.isTypeAbsence
        }, {
          model: 'worktypeid',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.getWorktypes.filter(x => !x.isabsence),
          is_list: false,
          click: (search) => false,
          change: this.checkForAbsenceType,
          select_text: 'name',
          select_value: '_id',
          label: this.$t('worktype'),
          class: 'xs12',
          is_visible: this.getTimerFormSettings ? this.getTimerFormSettings.worktype : false
        }, {
          model: 'timecode',
          type: this.formType.SELECT,
          rules: [],
          label: this.$t('timeCode'),
          class: 'xs12',
          items: this.getTimeCodes,
          select_text: 'code',
          select_value: '_id',
          change: () => {
            if (this.timerObj._id) this.saveRecord()
          },
          is_visible: (this.getTimerFormSettings ? this.getTimerFormSettings.timecode : false) && !this.isTypeAbsence
        }, {
          model: 'overtime50',
          type: this.formType.TEXT,
          rules: this.timerObj.overtime50 ? this.$_HourValidation : [],
          label: `50% ${this.$t('overtime')}`,
          class: 'sm6 xs12',
          disabled: this.isReadOnly,
          is_visible: (this.getTimerFormSettings ? this.getTimerFormSettings.overtime_50 : false) && !this.isTypeAbsence
        }, {
          model: 'overtime100',
          type: this.formType.TEXT,
          rules: this.timerObj.overtime100 ? this.$_HourValidation : [],
          label: `100% ${this.$t('overtime')}`,
          class: 'sm6 xs12',
          disabled: this.isReadOnly,
          is_visible: (this.getTimerFormSettings ? this.getTimerFormSettings.overtime_100 : false) && !this.isTypeAbsence
        }, {
          model: 'toinvoice',
          type: this.formType.TEXT,
          rules: this.timerObj.toinvoice ? this.$_toInvoiceValidation : [],
          label: this.$t('invoiceTo'),
          class: 'xs12',
          disabled: this.isReadOnly,
          change: () => {
            if (this.timerObj._id) this.saveRecord()
          },
          is_visible: !!((this.getTimerSettings.setinvoiceonlybyadmin ? this.userDetails.isadmin : true) && (this.getTimerFormSettings && this.getTimerFormSettings.to_invoice))
        }, {
          model: 'breakhr',
          type: this.formType.TEXT,
          rules: this.timerObj.breakhr ? this.$_HourValidation : [],
          label: this.$t('breakHours'),
          class: 'sm6 xs12',
          disabled: this.isReadOnly,
          is_visible: (this.getTimerFormSettings ? this.getTimerFormSettings.breakhrs : false) && !this.isTypeAbsence
        }, {
          model: 'taskid',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.listOfTasks,
          is_list: false,
          click: (search) => false,
          select_text: 'name',
          select_value: '_id',
          change: () => {
            if (this.timerObj._id) this.saveRecord()
          },
          label: this.$t('task'),
          class: 'xs12',
          is_visible: (this.getTimerFormSettings ? this.getTimerFormSettings.task : false) && !this.isTypeAbsence
        }, {
          model: 'comments',
          type: this.formType.TEXTAREA,
          rules: [],
          change: () => {
            if (this.timerObj._id) this.saveRecord()
          },
          label: this.$t('comments'),
          class: 'xs12',
          is_visible: this.getTimerFormSettings ? this.getTimerFormSettings.comments : false
        }, {
          model: 'internalcomments',
          type: this.formType.TEXTAREA,
          rules: [],
          change: () => {
            if (this.timerObj._id) this.saveRecord()
          },
          disabled: this.isReadOnly,
          label: this.$t('internalComments'),
          class: 'xs12',
          is_visible: (this.getTimerFormSettings ? this.getTimerFormSettings.internal_comments : false) && !this.isTypeAbsence
        }],
        buttons: []
      }
    }
  },
  created () {
    this.initiate()
    this.currentDate = this.$formatter.getCurrentDate(this.userDetails.dateformat)
  },
  watch: {
    'timerObj.customerSearch' (val) {
      if (val === undefined) return
      if (val && this.enableCustomerSearch) this.loadCustomerData()
      // else this.listOfCustomers = []
    },
    'timerObj.projectSearch' (val) {
      if (val === undefined) return
      if (val && this.enableProjectSearch && !val.includes('-')) this.loadProjectData(val)
      // else this.getCurrentProjects = []
    }
  },
  mounted () {
    this.timerObj.userid = this.userDetails._id
    this.getRunningStemp()
    window.onresize = () => {
      this.screenResize()
    }
    this.$root.$on('taskProjectId', (skip, searchItem) => {
      this.getTasksByProject(this.timerObj.projectid, skip, searchItem)
    })
  },
  methods: {
    async initiate () {
      await this.$store.dispatch('getTimerFormSettings')
        .then(async () => {
          await this.$store.dispatch('getWorktypes').then(async () => {
            await this.$store.dispatch('getTimerSettings').then(async () => {
              if (this.getTimerSettings.filteraccountsbyassignee && this.getTimerFormSettings.customer) {
                await this.loadCustomerData()
                this.enableProjectSearch = true
              }
              if (this.getTimerSettings.filterprojectsbyassignee) await this.loadProjectData()
            })
            if (!this.timerObj._id) {
              const result = this.getWorktypes.find(x => x.isdefault)
              if (result) {
                this.timerObj.worktypeid = result._id
                this.isTypeAbsence = !!(result.isabsence)
              }
            }
            if (!this.getTimerFormSettings.customer) this.getProjects()
          }).finally(() => {
            this.$store.dispatch('getTimeCodes')
            if (this.timerObj._id && this.timerObj.taskid && this.timerObj.projectid) this.getTasksByProject(this.timerObj.projectid, this.skip, this.searchItem)
            if (!this.timerObj._id) this.prefillCustomerAndProject()
          })
        })
    },
    async prefillCustomerAndProject ({ setworktype } = {}) {
      let stempFormPreference = localStorage.getItem(`${process.env.VUE_APP_STORE_NAME}_${this.userDetails.tenantname}_stempFormPreference`)
      stempFormPreference = stempFormPreference ? JSON.parse(stempFormPreference) : null
      if (stempFormPreference) {
        if (stempFormPreference.customer && this.getTimerFormSettings.customer) {
          let customerArray = []
          if (!this.getTimerSettings.filteraccountsbyassignee) {
            await this.loadCustomerData({ searchterm: stempFormPreference.customer.name }).then((data) => { customerArray = data })
          } else customerArray = this.listOfCustomers
          if (customerArray.map((customer) => customer._id).includes(stempFormPreference.customer._id)) {
            this.$set(this.timerObj, 'customerid', stempFormPreference.customer._id)
            await this.customerChangeHandler()
          }
        }

        if (this.getTimerFormSettings.customer && !this.getTimerSettings.filteraccountsbyassignee) {
          if (this.getTimerFormSettings.project && stempFormPreference.project && this.getCurrentProjects.map((project) => project._id).includes(stempFormPreference.project._id)) {
            this.timerObj.projectid = stempFormPreference.project._id
            this.projectChangeHandler()
          }
        } else if (this.getTimerFormSettings.project && stempFormPreference.project) {
          await this.loadProjectData()
            .then((data) => {
              if (data.map((project) => project._id).includes(stempFormPreference.project._id)) {
                this.timerObj.projectid = stempFormPreference.project._id
                this.projectChangeHandler()
              }
            })
        }

        if (setworktype) {
          const result = this.getWorktypes.find(x => x.isdefault)
          if (result) this.$set(this.timerObj, 'worktypeid', result._id)
        }
      }
    },
    async customerChangeHandler () {
      if (this.enableCustomerSearch) this.getCurrentProjects = []
      // if (this.getTimerSettings.filterprojectsbyassignee) await this.loadProjectData()
      await this.loadProjectData()
    },
    projectChangeHandler () {
      if (this.timerObj.projectid && this.timerObj.projectid !== 0) this.getTasksByProject(this.timerObj.projectid, this.skip, this.searchItem)
      else this.listOfTasks = []
    },
    getProjects () {
      // this.$api.execute('get', 'projects/get_current_projects').then((response) => {
      this.$api.execute('get', `${this.getTimerSettings.filterprojectsbyassignee ? `projects/get_current_projects?user_id=${this.userDetails._id}&show_completed=false` : 'projects/get_current_projects?show_completed=false'}`).then((response) => {
        if (response && response.data.length > 0) {
          const result = []
          response.data.forEach(element => {
            result.push({
              _id: element._id,
              projectName: element.number ? `${element.number} - ${element.name}` : `${element.name}`
            })
          })
          this.setCustomerProjectList('enableProjectSearch', 'getCurrentProjects', result)
          // this.getCurrentProjects = result
        }
      })
    },
    checkForAbsenceType () {
      const result = this.getWorktypes.find(x => x._id === this.timerObj.worktypeid && x.isabsence)
      if (result) {
        this.timerObj.type = 'non_working'
        this.isTypeAbsence = true
      } else {
        this.isTypeAbsence = false
        this.timerObj.type = 'working'
      }
    },
    // getTasksByProject (projectId) {
    //   this.$api.execute('get', `tasks/get_by_project/${projectId}`).then(response => {
    //     // this.listOfTasks = response.data
    //     // let userAssignedTasks = response.data.filter(x => x.assigned_to === this.userDetails._id)
    //     this.listOfTasks = this.$formatter.cloneVariable(response.data)
    //   })
    // },
    getTasksByProject (projectId, skip, searchItem) {
      const limit = 30
      this.$api.execute('get', `tasks/get_by_project/${projectId}/${skip}/${limit}?searchTerm=${searchItem}`).then(response => {
        response.data.data.forEach(task => {
          this.listOfTasks.push(task.data)
        })
      })
    },
    start () {
      if (this.$refs.hourReference.$refs.validateForm.validate()) {
        this.startTimer()
        // save customer & project for next new timer
        const customer = this.listOfCustomers.find((customer) => customer._id === this.timerObj.customerid)
        const project = this.getCurrentProjects.find((project) => project._id === this.timerObj.projectid)
        localStorage.setItem(`${process.env.VUE_APP_STORE_NAME}_${this.userDetails.tenantname}_stempFormPreference`, JSON.stringify({ customer, project }))
      }
    },
    stopTimer () {
      if (this.$refs.hourReference.$refs.validateForm.validate()) {
        this.loading = true
        const payload = { datetime: this.$moment().format('YYYY-MM-DDTHH:mm:ss') }
        this.$api.execute('put', `stamptimes/stop/${this.timerObj._id || ''}`, payload)
          .then(() => {
            if (this.webWorkerInstance) this.webWorkerInstance.terminate()
            this.webWorkerInstance = null
            this.$store.commit('clearTimerValue')
            // this.$root.$emit('showOrHideTimerRunningButton', false)
            this.$eventBus.$emit('stopTimer')
            this.time = '00:00:00'
            this.$refs.hourReference.$refs.validateForm.reset()
            this.timerObj = {}
            window.localStorage.setItem('is_timer_running', false)
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updatedSuccess' })
            this.loading = false
          })
          .finally(() => {
            this.prefillCustomerAndProject({ setworktype: true })
          })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    saveRecord () {
      this.timerObj.userid = this.userDetails._id
      let model = this.$formatter.cloneVariable(this.timerObj)
      model.start_at = model.start_at ? model.start_at : this.$moment().format('YYYY-MM-DDTHH:mm:ss')
      if (this.getTimerSettings.setinvoiceonlybyadmin ? this.userDetails.isadmin : true) {
        const transformNumber = { number: { list: ['toinvoice'], format: 'replaceCommaWithDot' } }
        model = this.$formatter.formatModel(model, transformNumber)
        model.toinvoice = model.toinvoice || 0
      }
      const url = model._id ? `stamptimes/update/${model._id}` : 'stamptimes/start'
      const method = model._id ? 'put' : 'post'
      const msg = model._id ? 'updatedSuccess' : 'addedSucess'
      this.$api.execute(method, url, model).then(response => {
        this.timerObj._id = response.data._id || null
        // this.timerObj = response.data
        const model = {
          timerobj: response.data,
          isTimerRunning: true
        }
        localStorage.setItem(`${process.env.VUE_APP_NAME}_timerObj`, JSON.stringify(model))
        this.$store.commit('setTimerObj', response.data)
        // this.$root.$emit('showOrHideTimerRunningButton', true)
        if (!this.timerObj.projectid) {
          // this.timerObj.projectid = 0
          this.listOfTasks = []
        }
        // if this.$route.path === '/stemp') this.setProjectCustomerList(true)
        this.$root.$emit('snackbar', {
          snackbar: true,
          color: 'success',
          text: msg
        })
      })
    },
    /* for customer field enabled in timer form settings */
    loadCustomerData ({ searchterm } = {}) {
      return new Promise((resolve) => {
        this.customerSearchLoading = true
        this.listOfCustomers = []
        const query = [
          { $match: { $and: [{ 'Data.type': 'customer' }, { 'Data.name': { $options: 'i', $regex: searchterm || this.timerObj.customerSearch || (this.timerObj.customer_info ? this.timerObj.customer_info[0].data.name : '') } }] } },
          { $project: { _id: 1, 'Data.name': 1 } }
        ]
        if (this.getTimerSettings.filteraccountsbyassignee) query[0].$match.$and.push({ $or: [{ 'Data.assigned_to._v': { $in: [this.userDetails._id] } }, { 'Data.assigned_to': this.userDetails._id }] })
        // if (this.getTimerSettings.filteraccountsbyassignee) query[0].$match.$and.push({ 'Data.assigned_to': this.userDetails._id })
        this.$api.execute('post', 'moduledata/Account/query', query)
          .then(async ({ data }) => {
            data.forEach(item => {
              item.name = item.data.name
            })
            this.setCustomerProjectList('enableCustomerSearch', 'listOfCustomers', data)
            await this.loadProjectData()
            resolve(data)
          }).finally(() => {
            this.customerSearchLoading = false
          })
      })
    },
    getRelatedProjects () {
      this.getCurrentProjects = []
      this.$api.execute('get', `moduledata/Project/search?relation_module=Account&relation_id=${this.timerObj.customerid}`)
        .then((response) => {
          if (response.data) {
            response.data = response.data.filter(x => x.data.status !== 'completed')
            const ProjectList = response.data.map((item) => {
              const data = item.data
              return {
                _id: item._id,
                projectName: data.number ? `${data.number} - ${data.name}` : `${data.name}`
              }
            })
            this.getCurrentProjects = this.$formatter.cloneVariable(ProjectList)
          }
        })
    },
    loadProjectData (searchTerm) {
      return new Promise((resolve) => {
        this.projectSearchLoading = true
        this.getCurrentProjects = []
        const query = [
          {
            $match: {
              $or: [
                { 'Data.name': { $options: 'i', $regex: searchTerm || '' } },
                { 'Data.number': { $options: 'i', $regex: searchTerm || '' } }
              ],
              $and: [
                { 'Data.status': { $ne: 'completed' } }
              ]
            }
          },
          { $project: { _id: 1, 'Data.name': 1, 'Data.number': 1 } }
        ]
        if (this.getTimerFormSettings && this.getTimerFormSettings.customer && this.timerObj.customerid) query[0].$match.$and.push({ Relations: { $elemMatch: { _id: { $eq: this.timerObj.customerid || '' } } } })
        // if (this.getTimerSettings.filterprojectsbyassignee) query[0].$match.$and.push({ $or: [{ 'Data.assigned_to': this.userDetails._id }, { 'Data.members._v': { $in: [this.userDetails._id] } }] })
        if (this.getTimerSettings.filterprojectsbyassignee) query[0].$match.$and.push({ $or: [{ 'Data.assigned_to._v': { $in: [this.userDetails._id] } }, { 'Data.assigned_to': this.userDetails._id }, { 'Data.members._v': { $in: [this.userDetails._id] } }] })
        this.$api.execute('post', 'moduledata/Project/query', query)
          .then(({ data }) => {
            if (data) {
              const projectList = data.map(({ _id, data: itemdata }) => ({
                _id,
                projectName: itemdata.number ? (`${itemdata.number} - ${itemdata.name}`) : itemdata.name
              }))
              this.setCustomerProjectList('enableProjectSearch', 'getCurrentProjects', projectList)
              resolve(projectList)
              // this.getCurrentProjects = this.$formatter.cloneVariable(ProjectList)
            }
          }).finally(() => {
            this.projectSearchLoading = false
          })
      })
    },
    setProjectCustomerList (isUpdate) {
      return new Promise((resolve) => {
        let customerItems = []
        if (isUpdate) {
          customerItems.push({ _id: this.timerObj.customerid, name: this.timerObj.customername || '' })
        } else if (this.timerObj.customerid && this.timerObj.customer_info.length > 0) {
          customerItems = this.timerObj.customer_info.map((item) => {
            return { _id: item._id, name: item.data.name }
          })
        }
        this.setCustomerProjectList('enableCustomerSearch', 'listOfCustomers', customerItems)
        let projectItems = []
        if (isUpdate) {
          projectItems.push({ _id: this.timerObj.projectid, projectName: this.timerObj.projectname || '' })
        } else if (this.timerObj.projectid && this.timerObj.project_info.length > 0) {
          projectItems = this.timerObj.project_info.map((item) => {
            return { _id: item._id, projectName: item.data.number ? `${item.data.number} - ${item.data.name}` : `${item.data.name}` }
          })
        }
        this.setCustomerProjectList('enableProjectSearch', 'getCurrentProjects', projectItems)
        resolve()
      })
    },
    setCustomerProjectList (searchEnableField, listName, listItems) {
      this[searchEnableField] = false
      this[listName] = this.$formatter.cloneVariable(listItems)
      setTimeout(() => {
        this[searchEnableField] = true
      }, 50)
    },
    enableSearch (fieldName) {
      this[fieldName] = true
    },
    screenResize () {
      this.maxFormHeight = `${(window.innerHeight / 10) - 10}vh`
      console.log(this.maxFormHeight)
    }
  // },
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     // let value = vm.$store.state.common.timerSettingObj && vm.$store.state.common.timerSettingObj.enablestamptime
  //     const value = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_NAME}_Timer_enablestamptime`))
  //     if (value) next()
  //     else next('/timer')
  //   })
  }
}
</script>
<style scoped>
.stemp-timer {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stemp-btn {
  font-size: 0.9rem;
  background-color: white !important;
}
.stemp {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50%;
}
.stop-btn {
  color: #ff5252;
  border: 1px solid #ff5252;
}
.start-btn {
  color: #4caf50;
  border: 1px solid #4caf50;
}
</style>
