import {
  PAGINATION, DELETE_HANDLER, DELETE_SINGLE_RECORD_HANDLER, USERS, MODULE_LIST, SHAREPPOINT_LIST, WORKTYPES, RELOAD_NEW_CONTENT, GET_RUNNING, CHECKLIST_FORMS, ACCOUNTS_LIST, USER_GROUPS,
  TIMER_FORM_SETTINGS, TIMECODES, SYSTEM_NOTIFICATION, TIMTER_SETTINGS
} from './actionsTypes'
import {
  SET_PAGINATION, SET_LISTVALUES, SHOW_DELETE_DIALOG, HIDE_DIALOG, SHOW_LOADER, HIDE_LOADER, MINIMIZEDMODAL, REMEMBER_LIST, SHAREPPOINT_FIELDS,
  SET_TIMER_VALUE, CLEAR_TIMER_VALUE, SET_TIMEROBJ, SITE_NEW_CONTENT, SHOW_SETTINGS_LOADER, HIDE_SETTINGS_LOADER, WARNING_CONTENT_SET, FILTERS_HISTORY_SET,
  MULTIPLE_TENANTS, STOP_ROUTE_LOADER, START_ROUTE_LOADER, SET_TENANT_PROFILE, SET_SYSTEM_NOTIFICATION
} from './mutationsTypes'
import { checklistApi, api } from '../plugins/axios_settings'

const state = {
  showDialog: false,
  deletePayload: {},
  showLoader: false,
  supportList: {},
  rememberOrder: {},
  isAdmin: false,
  FORM_TYPE: {
    TEXT: 'text',
    TEXTAREA: 'textarea',
    CHECKBOX: 'checkbox',
    SELECT: 'select',
    AUTO_COMPLETE: 'auto_complete',
    AUTO_COMPLETE_LIVE: 'auto_complete_live',
    AWB_AUTO_COMPLETE: 'auto_complete_search',
    COMBOBOX: 'combobox',
    DATE: 'date',
    PASSWORD: 'password',
    NUMBER: 'number',
    DATEPICKER: 'datepicker',
    QUICKADD: 'quickadd',
    TIMEPICKER: 'timepicker',
    FILES: 'files',
    ALERT: 'alert',
    COLORPICKER: 'color',
    BUTTON: 'btn',
    BTNDROPDOWN: 'btnDropdown',
    RADIO: 'radio',
    DIVIDER: 'divider',
    HEADING: 'heading',
    FOOTERINFORMATION: 'footerinfo',
    HTMLFIELD: 'htmlfield'
  },
  // List Pages
  USER_INDEX: {},
  CRM_INDEX: {},
  TENANTINDEX: {},
  COMMON_INDEX: {},
  TICKETSTATUS_INDEX: {},
  DOCUMENT_TEMPLATE_INDEX: {},
  TICKETTYPE_INDEX: {},
  PRIORITIES_INDEX: {},
  MAIL_CONFIG_INDEX: {},
  TICKETS_INDEX: {},
  ACCOUNT_TICKETS: {},
  PROJECT_ROLES_INDEX: {},
  LANGUAGES_INDEX: {},
  MODULES_INDEX: {},
  PROPS_INDEX: {},
  FORM_PROPS_INDEX: {},
  TIME_CODE_INDEX: {},
  // Filters
  F_TICKETS: {},
  F_ACCOUNT_TICKETS: {},
  // Common Lists
  listOfUsers: [],
  listOfModules: [],
  quickAddModules: [],
  listOfWorktypes: [],
  // Modal minimize
  listOfMinimizedModal: [],
  sharepointFieldList: [],
  loadRouter: false,
  sharepointProperties: [],
  sharepointDynamicFields: [],
  // Stamp timer
  isTimerRunning: false,
  currentRunningTime: '',
  timerObj: {},
  // service worker
  isNewContentAvailable: false,
  activeSW: {},
  listOfChecklistForms: [],
  listOfAccounts: [],
  listOfUserGroups: [],
  showPageLoader: false,
  crmWarningObj: null, // { title: String, type: String (error-warning), content: String, backLink: String (Proper Link with /)}
  timerFormSettingObj: null,
  timerSettingObj: {},
  listOfTimeCodes: [],
  tenantProfileObj: {},
  multipleTenant: [],
  hasMultipleTenant: false,
  listOfTenantsAndWorkspaces: []
}

const getters = {
  formType (state) {
    return state.FORM_TYPE
  },
  listOfTenantsAndWorkspaces (stateObj) {
    return stateObj.listOfTenantsAndWorkspaces
  },
  loadRouter (state) {
    return state.loadRouter
  },
  showDialog (state) {
    return state.showDialog
  },
  deletePayload (state) {
    return state.deletePayload
  },
  getUsers (state) {
    return state.listOfUsers
  },
  timerData (state) {
    return { isTimeRunning: state.isTimerRunning, runningTime: state.currentRunningTime }
  },
  getWorktypes (state) {
    return state.listOfWorktypes
  },
  showLoader (state) {
    return state.showLoader
  },
  listOfMinimizedModal (state) {
    return state.listOfMinimizedModal
  },
  listOfModules (state) {
    return state.listOfModules
  },
  listOfQuickaddModules (state) {
    return state.quickAddModules
  },
  listOfFields (state) {
    return state.sharepointFieldList
  },
  listOfProperties (state) {
    return state.sharepointProperties
  },
  getTimerObj (state) {
    return state.timerObj
  },
  getTimerSettings (stateObj) {
    return stateObj.timerSettingObj
  },
  getNewContentAvailable (state) {
    return state.isNewContentAvailable
  },
  getAccountList (state) {
    return state.listOfAccounts
  },
  getUserGroups (state) {
    return state.listOfUserGroups
  },
  getPageLoader (state) {
    return state.showPageLoader
  },
  getWarningPageData (state) {
    return state.crmWarningObj
  },
  getTimerFormSettings (state) {
    return state.timerFormSettingObj
  },
  getTimeCodes (state) {
    return state.listOfTimeCodes
  },
  // getTimerSettings (stateObj) {
  //   return stateObj.timerSettingObj
  // },
  getListOfFiltersHistory (state) {
    return state.listOfFiltersHistory
  },
  getTenantProfileObj (stateObj) {
    return stateObj.tenantProfileObj
  }
}

const actions = {
  [PAGINATION] (context, paginationObj) {
    context.commit(SET_PAGINATION, paginationObj)
  },
  [USERS] ({ commit }) {
    return new Promise((resolve, reject) => {
      const model = [{
        $match: { IsActive: true }
      }]
      api.post('users/query', model)
        .then(({ data }) => {
          data.forEach((user) => {
            user.name = (user.lastname) ? `${user.firstname} ${user.lastname}` : `${user.firstname}`
            user.alternateid = JSON.parse(JSON.stringify(`user_${user._id}`))
            user.isUser = true
          })
          const model = { type: 'listOfUsers', data: data }
          commit(SET_LISTVALUES, model)
          resolve(data)
        })
    })
  },
  [USER_GROUPS] ({ commit }) {
    return new Promise((resolve, reject) => {
      const model = [{
        $project: { Name: 1, Description: 1, Is_Administrative_Level: 1 }
      }]
      api.post('usergroups/query', model)
        .then((response) => {
          response.data.forEach(group => {
            group.alternateid = JSON.parse(JSON.stringify(`group_${group._id}`))
          })
          const model = { type: 'listOfUserGroups', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [TIMTER_SETTINGS] ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('timersettings')
        .then((response) => {
          const model = { type: 'timerSettingObj', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [DELETE_HANDLER] ({ commit }, model) {
    return new Promise((resolve, reject) => {
      const modelObj = { data: { ids: model.ids } }
      const deleteAPI = model.fromCheckList ? checklistApi : api
      if (model.isSoftDelete) {
        api.post(model.url, { ids: model.ids }).then(() => {
          resolve()
        }).catch((error) => {
          reject(error)
        })
      } else {
        deleteAPI.delete(model.url, modelObj).then(() => {
          resolve()
        }).catch((error) => {
          reject(error)
        })
      }
    })
  },
  [DELETE_SINGLE_RECORD_HANDLER] ({ commit }, model) {
    return new Promise((resolve, reject) => {
      const modelObj = { data: { moduleid: model.moduleid, recordid: model.recordid } }
      api.delete(model.url, model.moduleid ? modelObj : undefined)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // [TIMTER_SETTINGS] ({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     api.get('timersettings')
  //       .then((response) => {
  //         const model = { type: 'timerSettingObj', data: response.data }
  //         commit(SET_LISTVALUES, model)
  //         resolve()
  //       })
  //   })
  // },
  async [MODULE_LIST] ({ commit }) {
    return new Promise((resolve, reject) => {
      const query = [{
        $sort: { Menu_Order: 1 }
      }]
      api.post('modules/query', query)
        .then((response) => {
          const userDetails = this.state.auth.userDetails
          const hasSaleAccess = userDetails.allowedfeatures && userDetails.allowedfeatures.length > 0 && userDetails.allowedfeatures.includes(5) // 5 -SALES AND MARKETING
          const groupsList = userDetails.groups
          if (groupsList && groupsList.length > 0) {
            // To  check whether user the is in admin level
            groupsList.forEach(element => {
              const result = this.state.common.listOfUserGroups.find(x => x._id === element)
              if (result) {
                // eslint-disable-next-line
                this.state.common.isAdmin = result.is_administrative_level ?  true : false
              }
            })
          }
          response.data.forEach(element => {
            element.hasManagePermission = false
            element.hasPermission = false
            // element.to = ['Sale', 'Project'].includes(element.name) ? `/module/${element.name}/${element._id}/dashboard` : `/module/${element.name}/${element._id}`
            element.to = `/module/${element.name}/${element._id}`
            element.is_custom = true
            // Setting submodules values for filter in list page
            if (element.submoduleascolumns) {
              element.submoduleascolumns.forEach(item => {
                item.label = `Module_${item.module}`
                item.isSubModule = true
              })
            } else if (element.submodules && element.submodules.length > 0) {
              element.submoduleascolumns = []
              element.submodules.forEach(item => {
                const model = {}
                model.label = `Module_${item}`
                model.isSubModule = true
                model.show_in_list = false
                model.module = item
                element.submoduleascolumns.push(model)
              })
            }
            // Permission handling
            if (this.state.auth.userDetails.isadmin || this.state.auth.userDetails.iscontentadmin) {
              element.hasPermission = true
              element.hasManagePermission = true
            } else {
              if (element && element.permissions && !element.permissions.length) element.hasPermission = true
              else if (element && element.permissions && element.permissions.length > 0 && groupsList) {
                const userPermission = element.permissions.find(x => x.user_id === this.state.auth.userDetails._id)
                if (userPermission) {
                  element.hasPermission = true
                  if (userPermission.access_level === 'manage') element.hasManagePermission = true
                } else {
                  let found = []
                  groupsList.forEach(item => {
                    found = [...found, ...element.permissions.filter(x => x.group_id === item)]
                  })
                  if (found && found.length > 0) {
                    const hasManageAccess = found.find(x => x.access_level === 'manage')
                    if (hasManageAccess) {
                      element.hasManagePermission = true
                      element.hasPermission = true
                    } else element.hasPermission = true
                  }
                }
              }
            }
          })
          if (!hasSaleAccess) response.data = response.data.filter(x => x.name !== 'Sale') // Checking Allowed feature
          let data = JSON.parse(JSON.stringify(response.data))
          data = data.filter(x => x.hasPermission)
          const quickAddModules = response.data.filter(x => x.hasManagePermission && x.isactive && !x.showonlyassubmodule)
          quickAddModules.forEach(element => {
            if (element.name === 'Sale') element.to = `/module/${element.name}/${element._id}`
          })
          const model = { type: 'listOfModules', data }
          const modules = { type: 'quickAddModules', data: quickAddModules }
          commit(SET_LISTVALUES, model)
          commit(SET_LISTVALUES, modules)
          resolve(data)
        })
    })
  },
  async [SHAREPPOINT_LIST] ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('sharepoint/get_fields')
        .then(({ data }) => {
          const model = (data && data.length) ? data.filter((field) => !(['Author', 'Editor'].includes(field.name))) : []
          commit(SHAREPPOINT_FIELDS, model)
          resolve()
        }).catch(() => {
          state.loadRouter = false
        })
    })
  },
  [WORKTYPES] ({ commit }) {
    return new Promise((resolve, reject) => {
      const query = [{ $match: { IsActive: true } }]
      api.get('worktypes', query)
        .then((response) => {
          const model = { type: 'listOfWorktypes', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [GET_RUNNING] ({ commit }, userId) {
    return new Promise((resolve, reject) => {
      /* test query method */
      const query = [
        { $match: { $and: [{ UserId: `${userId}` }, { Stopped_At: null }] } },
        {
          $lookup: {
            let: { customerId: '$CustomerId' },
            from: 'Account',
            pipeline: [
              {
                $match: {
                  $expr: {
                    $eq: ['$_id', {
                      $convert: {
                        input: '$$customerId',
                        to: 'objectId',
                        onError: { error: true },
                        onNull: { isnull: true }
                      }
                    }]
                  }
                }
              },
              { $project: { _id: 1, 'Data.name': 1 } }
            ],
            as: 'customer_info'
          }
        },
        {
          $lookup: {
            let: { projectId: '$ProjectId' },
            from: 'Project',
            pipeline: [
              {
                $match: {
                  $expr: {
                    $eq: ['$_id', {
                      $convert: {
                        input: '$$projectId',
                        to: 'objectId',
                        onError: { error: true },
                        onNull: { isnull: true }
                      }
                    }]
                  }
                }
              },
              { $project: { _id: 1, 'Data.name': 1, 'Data.number': 1 } }
            ],
            as: 'project_info'
          }
        }
      ]
      api.post('stamptimes/query', query).then((response) => {
        if (response.data && response.data.length > 0) {
          const model = response.data[0]
          if (model && model.start_at) {
            const getModel = { time: model.start_at, ...model }
            resolve(getModel)
          }
        } else resolve()
      }).catch((ex) => {
        reject(ex)
      })

      /* api.get(`stamptimes/get_running/${userId}`)
        .then(response => {
          if (response.data) {
            let model = response.data
            if (model && model.start_at) {
              let time = getCalculatedTime(model.start_at)
              let getModel = { time, ...model }
              resolve(getModel)
            }
          }
        })
        .catch((ex) => {
          reject(ex)
        }) */
    })
  },
  [CHECKLIST_FORMS] ({ commit }, module) {
    return new Promise((resolve, reject) => {
      const query = [{ $match: { Is_Active: true } }]
      checklistApi.post('forms/query', query)
        .then((response) => {
          const model = { type: 'listOfChecklistForms', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [ACCOUNTS_LIST] ({ commit }, module) {
    return new Promise((resolve, reject) => {
      const query = [{ $match: { Is_Active: true } }]
      api.get('moduledata/Account', query)
        .then((response) => {
          const model = { type: 'listOfAccounts', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [RELOAD_NEW_CONTENT] ({ state, commit }) {
    state.activeSW.waiting.postMessage({ action: 'skipWaiting' })
    commit(RELOAD_NEW_CONTENT)
  },
  /* Get TimerFormSettings */
  [TIMER_FORM_SETTINGS] ({ commit }) {
    return api.get('timerformsettings').then(response => {
      if (response && response.data) {
        const model = { type: 'timerFormSettingObj', data: response.data }
        commit(SET_LISTVALUES, model)
      }
    })
  },
  /* Get Timecodes */
  [TIMECODES] ({ commit }) {
    return new Promise((resolve, reject) => {
      const model = [{
        $match: { IsActive: true }
      }]
      api.post('timecodes/query', model)
        .then((response) => {
          const model = { type: 'listOfTimeCodes', data: response.data }
          commit(SET_LISTVALUES, model)
          resolve()
        })
    })
  },
  [SYSTEM_NOTIFICATION] ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('systemnotification').then(async response => {
        if (response && response.data) {
          window.localStorage.setItem(`${process.env.VUE_APP_NAME}_systemNotification`, JSON.stringify(response.data))
          await resolve(response.data)
          const listofSystemNotification = { type: 'listofSystemNotification', data: response.data }
          commit(SET_SYSTEM_NOTIFICATION, listofSystemNotification)
        }
      }).catch((err) => reject(err))
    })
  }
}

const mutations = {
  [SET_PAGINATION] (state, paginationObj) {
    state[paginationObj.type] = paginationObj.data
  },
  [SET_LISTVALUES] (state, obj) {
    state[obj.type] = obj.data
  },
  [SHAREPPOINT_FIELDS] (state, obj) {
    state.loadRouter = false
    state.sharepointFieldList = obj.data
  },
  [REMEMBER_LIST]: (state, pagination) => {
    state[pagination.type] = pagination.data
  },
  [SHOW_DELETE_DIALOG]: (state, data) => {
    state.deletePayload = data
    state.showDialog = true
  },
  [HIDE_DIALOG] (state) {
    state.showDialog = false
  },
  [SHOW_LOADER] (state) {
    state.showLoader = true
  },
  [HIDE_LOADER] (state) {
    state.showLoader = false
  },
  [MINIMIZEDMODAL] (state, data) {
    data.isShow = true
    state.listOfMinimizedModal.push(data)
  },
  [SET_TIMER_VALUE] (state, time) {
    state.isTimerRunning = true
    state.currentRunningTime = time
  },
  [CLEAR_TIMER_VALUE] (state) {
    state.isTimerRunning = false
    state.currentRunningTime = ''
  },
  [SET_TIMEROBJ] (state, obj) {
    state.timerObj = obj
  },
  [SITE_NEW_CONTENT]: (state, data) => {
    state.activeSW = data
    state.isNewContentAvailable = true
  },
  [RELOAD_NEW_CONTENT]: (state) => {
    state.isNewContentAvailable = false
  },
  [SHOW_SETTINGS_LOADER]: (state) => {
    state.showPageLoader = true
  },
  [HIDE_SETTINGS_LOADER]: (state) => {
    state.showPageLoader = false
  },
  [WARNING_CONTENT_SET]: (state, obj) => {
    state.crmWarningObj = JSON.parse(JSON.stringify(obj))
  },
  [FILTERS_HISTORY_SET]: (state) => {
    const filtersList = localStorage.getItem('BusinessFlow365_filtersHistory')
    state.listOfFiltersHistory = (filtersList) ? JSON.parse(filtersList) : []
  },
  [MULTIPLE_TENANTS]: (stateObj, obj) => {
    // eslint-disable-next-line
    stateObj.hasMultipleTenant = obj.tenants && obj.tenants.length > 0 ? true : false
    stateObj.multipleTenant = obj.tenants
    stateObj.tenantProfileObj = obj
    setTimeout(() => {
      stateObj.loadRouter = false
    }, 100)
  },
  [STOP_ROUTE_LOADER]: (stateObj) => {
    setTimeout(() => {
      stateObj.loadRouter = false
    }, 300)
  },
  [START_ROUTE_LOADER]: (stateObj) => {
    stateObj.loadRouter = true
  },
  [SET_TENANT_PROFILE] (stateObj, obj) {
    stateObj.tenantProfileObj = obj
  },
  [SET_SYSTEM_NOTIFICATION] (stateObj, systemNotification) {
    stateObj[systemNotification.type] = systemNotification.data
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
